import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DialogState {
  isDialogOpen: boolean;
  dialogContent: string | undefined;
  dialogData?: any;
  isTransactionsrDialogOpen: boolean;
  isNetworkDialogOpen: boolean;
  networkDialogData: any;
}

export interface OpenDialogPayload {
  type: string;
  payload?: any;
}

const initialState: DialogState = {
  isDialogOpen: false,
  dialogContent: undefined,
  dialogData: undefined,
  isTransactionsrDialogOpen: false,
  isNetworkDialogOpen: false,
  networkDialogData: undefined,
};

export const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    openDialog: (
      state: DialogState,
      action: PayloadAction<OpenDialogPayload>
    ) => {
      state.isDialogOpen = true;
      state.dialogContent = action.payload.type;
      state.dialogData = action.payload.payload;
    },
    closeDialog: (state: DialogState) => {
      state.isDialogOpen = false;
      state.dialogContent = undefined;
    },
    handleTransactionsDialog: (
      state: DialogState,
      action: PayloadAction<boolean>
    ) => {
      state.isTransactionsrDialogOpen = action.payload;
    },
    openNetworkDialog: (state: DialogState, action: PayloadAction<any>) => {
      state.isNetworkDialogOpen = true;
      state.networkDialogData = action.payload;
    },
    closeNetworkDialog: (state: DialogState) => {
      state.isNetworkDialogOpen = false;
      state.networkDialogData = undefined;
    },
  },
});

export const {
  openDialog,
  closeDialog,
  handleTransactionsDialog,
  closeNetworkDialog,
  openNetworkDialog,
} = dialogSlice.actions;

export default dialogSlice.reducer;
